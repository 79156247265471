<template>
	<svg class="" :style="colorStyles"><use :xlink:href="url"></use></svg>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue';

import svgUrl from '@/assets/images/avatars.svg?url';
import type { Avatar } from '@/types';

const props = defineProps<{
	avatar: Avatar;
}>();

const colorStyles = computed(() => {
	return {
		'--background': props.avatar.colors.background,
		'--skin': props.avatar.colors.skin,
		'--skin_neck': props.avatar.colors.skin_neck,
		'--facial_hair': props.avatar.colors.facial_hair,
		'--undercut': props.avatar.colors.undercut,
		'--hair': props.avatar.colors.hair,
		'--shirt': props.avatar.colors.shirt,
		'--accessory': props.avatar.colors.accessory,
		'--eyes': '#832745',
		'--mouth': '#ffffff',
		'--shirt_neck': '#382b3d',
	};
});
const url = computed(() => {
	return svgUrl + `#avatar-${props.avatar.style}`;
});
</script>

<style scoped>
svg {
	width: 100%;
	aspect-ratio: 3 / 4;
}
</style>
