import { RouteComponent } from 'vue-router';

import AppLayout from '@/components/layout/AppLayout.vue';
import BlankLayout from '@/components/layout/BlankLayout.vue';
import NotFoundView from '@/views/NotFoundView.vue';

import { ifAuthenticated, ifNotAuthenticated } from './guards';

export default [
	{
		path: '',
		redirect: { name: 'search' },
	},

	{
		path: '',
		component: AppLayout,
		children: [
			{
				path: '/search',
				name: 'search',
				component: (): Promise<RouteComponent> => import('@/views/dictionary/SearchView.vue'),
				beforeEnter: ifAuthenticated,
			},
			{
				path: '/search-history',
				name: 'search-history',
				component: (): Promise<RouteComponent> =>
					import('@/views/dictionary/SearchHistoryView.vue'),
				beforeEnter: ifAuthenticated,
			},
			{
				path: '/favorites',
				name: 'favorites',
				component: (): Promise<RouteComponent> =>
					import('@/views/dictionary/FavoritesView.vue'),
				beforeEnter: ifAuthenticated,
			},
			{
				path: '/entry/:id',
				name: 'entry',
				component: (): Promise<RouteComponent> => import('@/views/dictionary/EntryView.vue'),
				beforeEnter: ifAuthenticated,
				meta: { title: 'Opslag' },
			},
		],
	},

	{
		path: '',
		component: BlankLayout,
		children: [
			{
				path: '',
				name: 'home',
				component: (): Promise<RouteComponent> => import('@/views/HomeView.vue'),
				beforeEnter: ifNotAuthenticated,
			},

			{
				path: '/token-login',
				name: 'token_login',
				component: (): Promise<RouteComponent> => import('@/views/auth/TokenLoginView.vue'),
				beforeEnter: ifNotAuthenticated,
			},

			// Catch-all
			{
				path: '/:pathMatch(.*)*',
				component: NotFoundView,
				meta: { title: 'Siden blev ikke fundet' },
			},
		],
	},
];
