<template>
	<Popover class="relative">
		<div class="inline-block" @pointerenter="open = true" @pointerleave="open = false">
			<slot />
		</div>

		<Transition
			enter-active-class="transition duration-200 ease-out"
			enter-from-class="translate-y-1 opacity-0"
			enter-to-class="translate-y-0 opacity-100"
			leave-active-class="transition duration-150 ease-in"
			leave-from-class="translate-y-0 opacity-100"
			leave-to-class="translate-y-1 opacity-0"
		>
			<PopoverPanel
				v-if="!disabled && open"
				static
				class="absolute z-10 mt-1 md:mt-2 w-max max-w-sm px-0 inset-full"
				:class="placementClasses"
			>
				<div
					class="overflow-hidden rounded-xl shadow-lg border py-1 md:py-2 px-2 md:px-4"
					:class="typeClasses"
				>
					<slot name="tooltip">
						<p class="font-light text-xs md:text-sm" :class="textClasses">
							{{ tooltip }}
						</p>
					</slot>
				</div>
			</PopoverPanel>
		</Transition>
	</Popover>
</template>

<script setup lang="ts">
import { Popover, PopoverPanel } from '@headlessui/vue';
import { computed, ref } from 'vue';

const props = withDefaults(
	defineProps<{
		tooltip?: string;
		placement?: 'left' | 'right' | 'center';
		type?: 'info' | 'action';
		disabled?: boolean;
	}>(),
	{
		tooltip: '',
		placement: 'left',
		type: 'info',
		disabled: false,
	},
);

const open = ref(false);

const placementClasses = computed(() => {
	switch (props.placement) {
		case 'left':
		default:
			return 'left-0';

		case 'right':
			return 'right-0';

		case 'center':
			return 'left-1/2 -translate-x-1/2';
	}
});

const typeClasses = computed(() => {
	switch (props.type) {
		case 'info':
		default:
			return 'bg-white dark:bg-martinique-400 border-gray-200 dark:border-martinique-300';

		case 'action':
			return 'bg-black dark:bg-gray-200 border-black dark:border-gray-200';
	}
});

const textClasses = computed(() => {
	switch (props.type) {
		case 'info':
		default:
			return 'text-gray-400 dark:text-martinique-100';

		case 'action':
			return 'text-white dark:text-gray-800';
	}
});
</script>
