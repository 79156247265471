<template>
	<figure class="avatar">
		<div class="background-color" :style="{ backgroundColor: avatar?.colors.background }"></div>
		<div v-if="avatar" class="avatar-container clip-half-circle">
			<img v-if="avatar?.url" alt="avatar" :src="avatarUrl" class="is-relative" />
			<AvatarSvg v-else :avatar="avatar" class="is-relative" />
		</div>
	</figure>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue';

import AvatarSvg from '@/components/ui/AvatarSvg.vue';
import type { Avatar } from '@/types';

const props = defineProps<{
	avatar: Avatar | undefined;
}>();

const avatarUrl = computed(() => {
	return props.avatar?.url ? props.avatar.url : undefined;
});
</script>

<style scoped>
.avatar {
	position: relative;
	width: 2.25rem;
	height: calc(2.25rem / 3 * 4);
	border-bottom-left-radius: calc(2.25rem / 2);
	border-bottom-right-radius: calc(2.25rem / 2);
}

.avatar-container {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	display: flex;
	overflow: hidden;
}

.background-color {
	position: absolute;
	bottom: 0;
	left: 0;
	border-radius: 50%;
	height: 2.25rem;
	width: 2.25rem;
}
.background-color:after {
	content: '';
	position: absolute;
	top: -5px;
	left: -5px;
	width: calc(100% + 10px);
	height: calc(100% + 10px);
	border-radius: 50%;
	border: 2px solid transparent;
	transition: border-color 200ms linear;
}
:deep(svg) {
	width: 2.25rem;
	height: calc(2.25rem / 3 * 4);
}
:deep(svg) use {
	width: 2.25rem;
	height: calc(2.25rem / 3 * 4);
}
</style>
